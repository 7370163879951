<template>
  <header>
    <img id="logo" src="./assets/logo.svg">
    <i id="blurb">Tech Platform</i>
  </header>
  <div id="connection-status" :class="{connected: connected}">
    <span class="msg-connected">Verbunden</span>
    <span class="msg-disconnected">Keine Verbindung</span>
  </div>
  <div id="nav" v-if="initialized">
    <router-link :to="{name:'Tresen'}">Tresen</router-link> | 
<!--    Stationen
    <router-link to="/items/Led Zeppelin">Led Zeppelin</router-link> |
    <router-link to="/items/Pink Floyd">Pink Floyd</router-link> |
    <router-link to="/items/The Grateful Dead">The Grateful Dead</router-link> |
    <router-link to="/items/The Doors">The Doors</router-link> |
    <router-link to="/items/Phish">Phish</router-link> |
   Verwaltung -->
    <router-link :to="{name: 'Dishes'}">Gerichte</router-link> |
    <router-link :to="{name: 'Modifiers'}">Modifiers</router-link> |
    <!-- <router-link to="./brands">Brands</router-link> -->
    <router-link :to="{name: 'Stations'}">Stationen</router-link> |
    <router-link :to="{name: 'History'}">Bestellungen</router-link> 
  </div>
  <template v-if="initialized">
    <router-view />
  </template>
  <footer v-if="initialized">
    © 2021 - 2022 CloudKanteen GmbH<br>
    <small><br><a @click.prevent="soundTest" href="">Sound Test</a></small>
  </footer>
</template>

<style lang="scss">
#app {
  font-family: Comfortaa, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  text-align: center;
  color: #2c3e50;
  margin: 10px;
  position: relative;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body, html {
  margin: 0;
}
header {
  display: flex;
  font-size: 30px;
  align-items: center;
  #blurb {
    font-weight: bold;
  }
  #logo {
    width: 80px;
    height: 80px;
    margin: 0 10px 0 0;
  }
}

/* latin-ext */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMDr0fIA9c.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMDrMfIA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LDr0fIA9c.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LDrMfIA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

input[type="text"] {
border: 1px solid #999;
padding: 4px;
border-radius: 4px;
}

table {
  td, th {
    padding: 8px;
  }
  td {
    vertical-align: top;
  }
  th {
    text-align: left;
  }
  border-collapse: collapse;

  tr:nth-child(odd) {
    background-color: #eee;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
}

table textarea {
width: 100%;
height: 67px;
box-sizing: border-box;
}

table img {
max-width: 90px;
max-height: 90px;
}

table  input[type="file"] {
    display: none;
}

input.dish_code {
width: 30px;
text-align: right;
}

input.price {
width: 60px;
text-align: right;
}

input.newdish {
margin-bottom: 10px;
}

section {
  margin: 0 0px;
  max-width: 100%;
  overflow: auto;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  margin-top: 25px;
}


#connection-status {
  font-size: 110%;
  font-weight: bold;
  padding: 0.5em;
  margin: 0.5em;
  position: fixed;
  right: 0;
  top: 0;
  color: red;
  border: 1px solid red;
  background-color: black;
  &.connected {
    display: none; // don't display success, just error

    color: green;
    border: 1px solid green;
    .msg-connected {
      display: inline;
    }
    .msg-disconnected {
      display: none;
    }
  }
  .msg-connected {
    display: none;
  }
  .msg-disconnected {
    display: inline;
  }
}

</style>

<script>

function offerDownload(filename, content) {
  console.log('download', filename, content)
}


export default {

  beforeMount() {
    //window.addEventListener('load', () => {
    this.messageAudio = new Audio('data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4Ljc2LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAAAwAAAbAAqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV////////////////////////////////////////////AAAAAExhdmM1OC4xMwAAAAAAAAAAAAAAACQDkAAAAAAAAAGw9wrNaQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/+MYxAAAAANIAAAAAExBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV/+MYxDsAAANIAAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV/+MYxHYAAANIAAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV')
    //this.messageAudio.play()
    this.messageAudio.src = require('@/assets/message-new-instant.mp3')
    //}, {'once': true})

    var routeMessage = (data) => {
      if (data.length == 0)
        "empty message".raise
      switch (data[0]) {
        case 'download':
          return offerDownload.apply(data)
        default:
          ("unknown message " + data[0]).raise
      }
    }

    this.onMessage = (e) => {
      console.info("received message")
      var data = JSON.parse(e.data)
      // If data is an object, this is data to update the store with (shorthand instad of store message)
      // If data is an array, route the message here
      if (data.isArray) {
        return routeMessage(data)
      }
      var n = 'orders' in this.$store.state ? this.$store.state.orders.length : -1
      this.$store.commit('update', data)
      if (n != -1 && data.orders) {
        if (data.orders.length > n) {
          // new order
          // don't audio-warn on update with only existing orders
          this.messageAudio.play()
          console.log("played")
        }
      }
      window.store = this.$store
    }
    this.onClose = (e) => {
      console.info('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      setTimeout(() => {
        this.connectSocket()
      }, 1000)
      this.$store.commit('update', {'connected': false})
    }
    this.connectSocket = () => {
      console.log(this,'route', this.$route.params['secret'])
      this.socket = new WebSocket(process.env.VUE_APP_API_URL.replace('https:', 'wss:').replace('http:', 'ws:') + '/platform/' + this.$route.params['venue'] + '/' + this.$route.params['secret']);
      this.socket.addEventListener("message", this.onMessage)
      this.socket.addEventListener("close", this.onClose)
      this.socket.addEventListener("open", this.onOpen)

      window.ws = window.socket = this.socket // TODO: get this straigh ASAP
    }
    this.onError = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket')
      this.socket.close()
    }
    this.onOpen = () => {
      console.info('Socket open')
      this.$store.commit('update', {'connected': true})
    }

    var lastVenue = null
    setTimeout(() => {
      this.connectSocket()

      lastVenue = this.$route.params.venue

      }, 300)

    window.addEventListener('hashchange', () => {
      // force reload if venue changes
      // to ensure data integrity
      // TODO: calling for a reload by websocket would be more elegant
      if ( ! this.$route) return
      if ( ! this.$route.params.venue) return

      var needsReload = lastVenue && (this.$route.params.venue != lastVenue)
      lastVenue = this.$route.params.venue
      if (needsReload) window.location.reload()
    })

  },
  beforeUnmount() {
    this.socket.close()
  },
  methods: {
    soundTest() {
      setTimeout(() => {
        console.log("doing sound test")
        this.messageAudio.currentTime = 0
        this.messageAudio.play()
      }, 100)
    }
  },
  computed: {
    initialized() {
      return undefined !== this.$store.state.orders
    },
    connected() {
      return this.$store.state.connected
    }
  }
  
};

</script>

