import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

var intl = Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export default {
  methods: {
    formatDatetime (date) {
      return dayjs(date).format('DD.mm.YYYY HH:mm:ss.SSS')
    },
    formatDate (date) {
      return dayjs(date).format('dddd, DD. MMMM YYYY')
    },
    formatTime (date) {
      return dayjs(date).format('HH:mm:ss')
    },
    as_eur(amount) {
      return intl.format(amount * 0.01) + " €"
    },
    as_percentage(percentage) {
      return percentage * 0.1
    }
  }
}

